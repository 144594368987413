import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";

registerLocale("nl-BE", nl);

import "react-datepicker/dist/react-datepicker.css";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOfferRequest: false,
      isLoading: false,
      isError: false,
      partyStartDate: new Date(),
      formData: {},
    };
  }

  handleDateChange(date) {
    this.setState({ partyStartDate: date });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (this.validateInput(target)) {
      let formData = { ...this.state.formData };
      formData[name] = value;

      this.setState({
        formData: formData,
      });
    }
  }

  validateInput(input) {
    let parentGroup = input.parentElement;
    let valid = true;

    // Required check
    let required = input.getAttribute("required") === "";
    if (required && (!input.value || input.value.length === 0)) {
      valid = false;
    }

    // Pattern check
    let pattern = input.getAttribute("pattern");
    if (pattern) {
      let regex = new RegExp(pattern);
      if (!regex.test(input.value)) {
        valid = false;
      }
    }

    if (required || pattern) {
      if (valid) {
        parentGroup.classList.add("is-valid");
        parentGroup.classList.remove("is-invalid");
      } else {
        parentGroup.classList.add("is-invalid");
        parentGroup.classList.remove("is-valid");
      }
    }
    return valid;
  }

  sectionToggle(e) {
    this.setState({ isOfferRequest: e.target.checked });
  }

  startLoading() {
    this.setState({ isLoading: true });
  }

  endLoading() {
    this.setState({ isLoading: false });
  }

  doSubmit(e) {
    e.preventDefault();
    this.startLoading();

    const formData = { ...this.state.formData };

    const allElements = e.target.querySelectorAll("input, textarea");
    if (Array.from(allElements).every(this.validateInput)) {
      if (this.state.isOfferRequest) {
        const formattedDate = `${this.state.partyStartDate.getFullYear()}-${this.state.partyStartDate.getMonth() +
          1}-${this.state.partyStartDate.getDate()}`;
        const formattedHour = `${this.state.partyStartDate.getHours()}u${this.state.partyStartDate.getMinutes()}`;

        formData.partyDate = formattedDate;
        formData.partyHour = formattedHour;

        formData.isOfferRequest = this.state.isOfferRequest;
        formData.partyLocationCountry = "BE";
      }

      return fetch(
        "https://api.thebrownfox.be/.netlify/functions/ritorno-contact",
        //"http://localhost:8888/.netlify/functions/ritorno-contact",
        {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          body: JSON.stringify(formData), // body data type must match "Content-Type" header
        }
      )
        .then(response => response.json())
        .then(response => {
          this.endLoading();
          if (response.success) {
            window.location.href = "/bedankt";
          } else {
            this.setState({ isError: true });
          }
        })
        .catch(() => {
          this.endLoading();
          this.setState({ isError: true });
        });
    } else {
      console.error("invalid entry");
      this.endLoading();
    }
  }
  render() {
    return (
      <section
        className="pattern-bg pt-8 pb-5"
        style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
        data-component="Lube.Contact"
      >
        <div className=" container">
          <div className="row">
            <div className="col-md-8">
              <div
                className={
                  "form-info-block" + (this.state.isError ? " hide" : "")
                }
              >
                <h2 className="title mb-2">
                  Contacteer ons nu vrijblijvend voor een voorstel
                </h2>

                <p className="mb-2">
                  Als u graag een voorstel van ons ontvangt, vul dan het
                  contactformulier in. Dan voorzien we zo snel mogelijk een
                  gepersonaliseerde offerte waar tevens ook onze volledige
                  werking in beschreven staat.
                </p>
              </div>

              <form
                className={
                  "form clearfix text-left" +
                  (this.state.isLoading ? " loading" : "")
                }
                method="POST"
                noValidate
                onSubmit={e => this.doSubmit(e)}
              >
                <div
                  className={
                    "form-error-block" + (this.state.isError ? "" : " hide")
                  }
                >
                  <h3>Er liep iets verkeerd, probeer later opnieuw</h3>
                  <p className="mt-2">
                    U kan ons steeds contacteren via mail{" "}
                    <a href={"mailto:" + this.props.email} className="goto">
                      {this.props.email}
                    </a>{" "}
                    of bel ons op het nummer{" "}
                    <a href={"tel:" + this.props.phone} className="goto">
                      {this.props.phone}
                    </a>
                  </p>
                </div>
                <div className="row">
                  <div className="col-5">
                    <div className="form-group">
                      <label htmlFor="Name">Voornaam:</label>
                      <input
                        className="form-control"
                        name="firstName"
                        onChange={e => this.handleChange(e)}
                        type="text"
                        required
                        autoComplete="first-name"
                      />
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="form-group">
                      <label htmlFor="Name">Naam:</label>
                      <input
                        className="form-control"
                        name="lastName"
                        onChange={e => this.handleChange(e)}
                        type="text"
                        required
                        autoComplete="family-name"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Phone">Telefoon:</label>
                  <input
                    className="form-control"
                    name="phone"
                    onChange={e => this.handleChange(e)}
                    type="text"
                    autoComplete="tel-national"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Email">Email:</label>
                  <input
                    className="form-control"
                    name="email"
                    onChange={e => this.handleChange(e)}
                    type="email"
                    required
                    autoComplete="email"
                    pattern='^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$'
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Body">Uw bericht:</label>
                  <textarea
                    className="form-control"
                    name="message"
                    onChange={e => this.handleChange(e)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="checkbox"
                    name="offerRequest"
                    onChange={this.sectionToggle.bind(this)}
                    id="offerRequest"
                    data-target="#offer-details"
                  />{" "}
                  <label htmlFor="offerRequest">Vraag een offerte aan</label>
                </div>
                <fieldset
                  className={
                    "mt-1 conditional-fieldset " +
                    (this.state.isOfferRequest ? "" : "hide")
                  }
                  id="offer-details"
                >
                  <hr />

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="partyDate">Datum:</label>
                        <DatePicker
                          className="form-control"
                          selected={this.state.partyStartDate}
                          onChange={e => this.handleDateChange(e)}
                          locale="nl-BE"
                          showTimeSelect
                          dateFormat="dd/MM/yyyy HH:mm"
                          timeFormat="HH:mm"
                          minDate={new Date()}
                        />
                        {/* <input
                          className="form-control"
                          name="partyDate"
                          onChange={e => this.handleChange(e)}
                          type="text"
                          required={this.state.isOfferRequest}
                        /> */}
                      </div>
                    </div>

                    {/* <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="partyHour">Startuur:</label>
                        <input
                          className="form-control"
                          name="partyHour"
                          onChange={e => this.handleChange(e)}
                          type="text"
                          required={this.state.isOfferRequest}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Aantal personen:</label>
                    <input
                      className="form-control"
                      name="partyPeople"
                      onChange={e => this.handleChange(e)}
                      type="number"
                      required={this.state.isOfferRequest}
                    />
                  </div>

                  <p>Locatie:</p>
                  <div className="form-group">
                    <label htmlFor="partyLocationStreetNr">Straat + Nr:</label>
                    <input
                      className="form-control"
                      name="partyLocationStreetNr"
                      onChange={e => this.handleChange(e)}
                      type="text"
                      required={this.state.isOfferRequest}
                      autoComplete="street-address"
                    />
                  </div>
                  <div className="row mb-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="partyLocationPostCode">Postcode:</label>
                        <input
                          className="form-control"
                          name="partyLocationPostCode"
                          onChange={e => this.handleChange(e)}
                          type="text"
                          required={this.state.isOfferRequest}
                          autoComplete="postal-code"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="partyLocationCity">Gemeente:</label>
                        <input
                          className="form-control"
                          name="partyLocationCity"
                          onChange={e => this.handleChange(e)}
                          type="text"
                          required={this.state.isOfferRequest}
                          autoComplete="address-level2"
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <button className="btn btn-primary">
                  Verzenden
                  <i className="icon-paper-plane" />
                </button>
              </form>
              <p className="mt-2">
                U kan steeds contact opnemen via mail{" "}
                <a href={"mailto:" + this.props.email} className="goto">
                  {this.props.email}
                </a>{" "}
                of bel ons op het nummer{" "}
                <a href={"tel:" + this.props.phone} className="goto">
                  {this.props.phone}
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactForm;
