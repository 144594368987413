import React from "react";

import Layout from "../components/organisms/layout";
import Footer from "../components/organisms/footer";
import Header from "../components/organisms/header";
import ContactForm from "../components/organisms/contact-form";

import Nav from "../components/molecules/nav";
import SEO from "../components/molecules/seo";

import headerImage from "../../static/design/img/header/pages/ritorno-kindvriendelijk.jpg";

const ContacteerOns = () => (
  <Layout>
    <SEO
      title="Contacteer ons"
      description="Had u toch nog vragen of wil u een offerte ontvangen? Contacteer ons nu vrijblijvend voor een voorstel."
    />

    <Nav />

    <Header
      heading="Neem contact met ons op"
      image={headerImage}
      darkBg={true}
    />

    <ContactForm phone="0032 (0)3 502 51 61" email="info@ritorno.be" />

    <Footer />
  </Layout>
);

export default ContacteerOns;
